import React,{Fragment} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import styles from '../../../../styles/modules/ui-components/accordion.module.scss';

interface Props {
  simple:boolean,
  completed?:false | boolean,
  title:string,
  subtitle?:string,
  children?: React.ReactNode,
  expanded?: boolean,
  onChange?:(isExpanded: boolean) => void,
  isCompleted?:boolean | false,
  name?: string | 'accordian',
  customStyle?: any,
  accordionTitleProps?:any,
  accordionTitleContentProps?:any,
  customAccordionSummaryStyle?:any,
}

const UIAccordion = (props:Props) => {
  let accordionTitleProps:any= props.accordionTitleProps || {};
  let accordionTitleContentProps:any = props.accordionTitleContentProps || {};
    return (
            <Accordion data-name={props.name} className={`${styles.accordion} ${props.customStyle? props.customStyle : ''}`} onChange={props.onChange} expanded={props.expanded} data-simple={props.simple} classes={{expanded:styles.accordion_expanded}} {...accordionTitleProps}>
                <AccordionSummary className={`${styles.accordion__summary} ${props.customAccordionSummaryStyle ? props.customAccordionSummaryStyle : ''}`} classes={{expanded:`${styles.accordion_expanded} ${styles.accordion_content}`}}>
                  <div className={styles.accordion_title_subtitle}>
                  <span className={`accordion-title ${styles.accordion__summary_title}`} {...accordionTitleContentProps}>{ props.title }</span>
                  {props.subtitle && <span className={`accordion-title ${styles.accordion__summary_subtitle}`} {...accordionTitleContentProps}>{props.subtitle}</span>}
                  </div>


                  <div className={styles.accordion__summary_right}>
                    {
                      props.isCompleted &&
                      <Fragment>
                        <span className={styles.accordion__summary_status_icon}></span>
                        {/* <span className={styles.accordion__summary_status_text}>completed</span> */}
                      </Fragment>
                    }
                    <span className={`accordion-arrow ${styles.accordion__summary_status_arrow}`}></span>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={`accordion-content ${styles.accordion__details}`}>
                      {
                        props.children
                      }
                </AccordionDetails>
            </Accordion>
    );
}


export default UIAccordion;
