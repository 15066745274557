import React from 'react';
import styles from '@/styles/RateCard/rate-group.module.scss';
import {formatNumber} from '../../../../../utils';

interface Props {
  groupName: string,
  items: {
    title: string,
    price: number,
    priority: number,
    fine_text_notation?: string | null,
  }[]
}

const RateCardGroup = (props:Props) => {

  return (
    <div className={styles.rate_group}>
      <table className={`${styles.rate_item} ${styles.rate_item__heading}`}>
        <tr>
          <th className={styles.description_column}>Description</th>
          <th className={`${styles.pricing_column} ${styles.pricing}`}>Total Price <span className={styles.pricing__tax_inclusion}>(incl. of GST)</span></th>
        </tr>
        {props.items.map((item, index) => {
          return (
            <tr key={`${props.groupName}-rate-item-${index}`} className={styles.rate_item}>
              <td className={styles.description_column}>{item.title}</td>
              <td className={`${styles.pricing_column} ${styles.pricing}`}>{formatNumber(item.price)}<span>{item.fine_text_notation ? item.fine_text_notation : ''}</span></td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}


export default RateCardGroup;
