import Config from '../../config';
import {GetCall} from '../helper'

const URLS = {
  "PLAN_SLUGS": Config.BASE_BUILD_API_URL + "/v3/feature/plan_slugs",
  "PLAN_DETAIL": Config.BASE_BUILD_API_URL + "/v3/feature/plan_detail",
  "TEST": Config.BASE_BUILD_API_URL + "/activation",
  "LOGIN": Config.BASE_API_URL + "/auth/activation/login",
  "PRODUCTS": Config.BASE_API_URL + "/v3/plan",
  "PRODUCTS_BUILD": Config.BASE_BUILD_API_URL + "/v3/plan",
  "CROSS_SELL": Config.BASE_API_URL + "/v3/plan/cross_sell_options",
  "CROSS_SELL_PRODUCTS": Config.BASE_API_URL+"/v3/shop/cross_sell_products",
  "COVERAGE": Config.BASE_BUILD_API_URL + "/v3/feature/coverage_details",
  "CITIES_AND_BRANDS_DISPLAY": Config.BASE_BUILD_API_URL + "/v3/feature/cities_and_brands_display",
  "PRODUCT_ATTRIBUTES": Config.BASE_BUILD_API_URL + "/v3/feature/product_attributes",
  "PRODUCT_COMPARE": Config.BASE_BUILD_API_URL + "/v3/feature/product_compare",
  "FEATURES": Config.BASE_BUILD_API_URL + "/v3/feature/product_features",
  "BRANDS": Config.BASE_BUILD_API_URL + "/v3/feature/brands",
  "COVERED_NOT_COVERED_TEXT":  Config.BASE_API_URL + '/v3/feature/covered_not_covered_text',
  "RATE_CARD": Config.BASE_API_URL + "/v3/plan/rate_card",
  "SKU_DISPLAY_CONFIG": Config.BASE_API_URL + "/v3/plan/get_sku_display_config"

}


export const GetPlanSlugs = async(data?: any) => {
  return await GetCall(URLS.PLAN_SLUGS, data, Config.DEFAULT_HEADER);
}

export const GetPlanDetails = async(data?:any) => {
  return await GetCall(URLS.PLAN_DETAIL, data, Config.DEFAULT_HEADER)
}

export const GetProductsBuild = async(data?: any) => {
  return await GetCall(URLS.PRODUCTS_BUILD, data, Config.DEFAULT_HEADER)
}

export const GetProducts = async(data?: any) => {
  return await GetCall(URLS.PRODUCTS, data, Config.DEFAULT_HEADER)
}

export const GetCrossSellDetails = async(data?: any) => {
  return await GetCall(URLS.CROSS_SELL, data, Config.DEFAULT_HEADER)
}

export const GetCoverageData = async(data?: any) => {
  return await GetCall(URLS.COVERAGE, data, Config.DEFAULT_HEADER)
}

export const GetProductCompareData = async(data?: any) => {
  return await GetCall(URLS.PRODUCT_COMPARE, data, Config.DEFAULT_HEADER)
}

export const GetCoveredNotCovered = async(data?: any) => {
  return await GetCall(URLS.COVERED_NOT_COVERED_TEXT, data, Config.DEFAULT_HEADER)
}

export const GetRateCard = async(data?: any) => {
  return await GetCall(URLS.RATE_CARD, data, Config.DEFAULT_HEADER)
}

export const GetSkuDisplayConfig = async(data?: any) => {
  return await GetCall(URLS.SKU_DISPLAY_CONFIG, data, Config.DEFAULT_HEADER)
}
