import React, {Component} from 'react';
import styles from '@/styles/RateCard/rate-card.module.scss';
import accordionStyles from '../../../../../styles/modules/ui-components/accordion.module.scss';
import UIAccordion from '../../../../../components/common/ui-components/surface/UIAccodion';
import RateCardGroup from './RateCardGroup';
import {GetRateCard} from '../../../../../api/plan';
import { getParamsObjectFromString } from '../../../../../utils'

interface Props {
  sku:number,
  isOpen:boolean
}

interface GroupState {
  [key: string]: boolean
}

interface State {
  groupState: GroupState,
  loading: boolean,
  popupSize: number,
  tries: number,
  appRender: boolean,
  data: {
    category_type: string,
    rate_card_groups: {
      id: number,
      rate_card_type: string,
      title: string,
      priority: number,
      rate_items: {
        title: string,
        price: number,
        priority: number,
        fine_text_notation?: string | null,
      }[]
    }[]
  } | null
}

class RateCard extends Component<Props, State> {
  state = {
    appRender: false,
    groupState: {},
    loading: true,
    popupSize: 0,
    tries: 0,
    data: {
      category_type: "",
      rate_card_groups: []
    },
  }

  componentDidMount = () => {
    this.setState({popupSize:Math.round(window.innerHeight*0.7)})
    if(getParamsObjectFromString(window.location.href)['urlParams']['app_render'] === "true"){
      this.setState({appRender: true})
    }
  }

  componentDidUpdate = () => {
    if (this.props.isOpen && this.state.data.rate_card_groups.length < 1 && this.state.tries < 2) {
      GetRateCard({
        application: 'website',
        sku: this.props.sku,
        type: 'spareparts'
      })
      .then((res:any) => {
        let groupState:any = {}
        res.data?.rate_card_groups?.forEach((group:any) => {
          groupState[group.title] = true
        })
        this.setState({
          data: {...res.data, rate_card_groups: res.data.rate_card_groups},
          loading: false,
          groupState,
          tries: this.state.tries + 1
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          data: {
            category_type: "",
            rate_card_groups: []
          },
          loading: false,
          tries: this.state.tries + 1
        })
      })
    }
    if (!this.props.isOpen && this.state.tries > 0) {
      this.setState({tries:0})
    }
  }

  handleAccordionChange = (group) => {
    let groupState = this.state.groupState;
    if (groupState[group.title] !== undefined) {
      groupState[group.title] = !groupState[group.title]
    }
    this.setState({groupState});
  }

  hasFineText = () => {
    let hasFineText = false;
    this.state.data?.rate_card_groups?.find((group:any) => {
      if(group.rate_items?.filter((item:any) => item.fine_text_notation !== null).length > 0) {
        hasFineText = true;
        return true;
      }
    })

    return hasFineText;
  }

  render() {
    return (
      <div className={`${styles.rate_card} ${this.state.appRender? styles.app_render:''}`} style={this.state.appRender? {} :  {height:this.state.popupSize}}>
        {this.state.loading ?
          <div className={styles.loading}>Loading...</div> :
          <React.Fragment>
            <div className={`${styles.title}`}>
              <h3>Spare Parts Rate Card</h3>
            </div>

            <div className={`${styles.rate_groups_container} app_rate_card`}>
              {this.state.data.rate_card_groups.length > 0 ? this.state.data?.rate_card_groups?.map((group:any, index) => {
                return (
                  <UIAccordion
                    key={`rate-card-group-${group.title}-${index}`} simple={true}
                    expanded={this.state.groupState[group.title] || false} title={group.title}
                    onChange={() => this.handleAccordionChange(group)}
                    customStyle={accordionStyles.rate_card_group}>
                    <RateCardGroup items={group.rate_items} groupName={group.title}/>
                  </UIAccordion>
                );
              })
              :
              <div className={styles.error}>No Rate Card Found</div>
              }
            </div>
            <div className={styles.fine_text}><span>*</span>All branded spare parts at MRP</div>
          </React.Fragment>}
      </div>
    )
  }
}


export default RateCard;
